import "../custom.css";

import { Button, Card, Col, Row } from "react-bootstrap";
import { useContext, useEffect, useRef, useState } from "react";

import { DocumentModal } from "./DocumentModal";
import { MainContext } from "../App";
import { RouteModal } from "./RouteModal";
import apiService from "../api";

export const formatNumberAsCurrency = (value) => {
  // Format the number as Turkish Lira currency (e.g., 12345.67 -> "12.345,67 ₺")
  return new Intl.NumberFormat("tr-TR", {
    style: "currency",
    currency: "TRY",
    minimumFractionDigits: 2,
  }).format(value);
};

export const CustomerDetailPage = (props) => {
  const { accessToken, sasToken, setSasToken, setProgress, reactGa } =
    useContext(MainContext);
  const [customerSummary, setCustomerSummary] = useState(null);
  const [show, showModal] = useState(false);
  const [docModal, showDocModal] = useState(false);
  const [selectedVehicles, setSelectedVehicles] = useState([]);
  const [selectedProjectName, setSelectedProjectName] = useState("");
  const [selectedProjectId, setSelectedProjectId] = useState("");
  const imageSize = 75;

  useEffect(() => {
    apiService
      .getWithFirstResolve("customersummary", accessToken, setProgress)
      .then((data) => {
        setSasToken(data.headers.get("Sas-Token"));
        localStorage.setItem("sasToken", data.headers.get("Sas-Token"));
        return data.json();
      })
      .then((data) => setCustomerSummary(data));
  }, [accessToken, setSasToken, setProgress]);

  const handlePassengerListChange = (passengerList) => {
    apiService
    .update(passengerList, "passengerList", accessToken, setProgress)
    .then((data) => {
        setCustomerSummary({...customerSummary, projects: [data, ...customerSummary?.projects?.filter(el => el?.id != passengerList?.id)]});
        setSelectedVehicles(
          data.routes
            ?.sort((a, b) => {
              return a.name.localeCompare(b.name);
            })
            ?.map((route) => ({
              ...route,
              ...customerSummary?.vehicles[route?.vehicleId],
            }))
        );
    });
  };

  return (
    <>
      <Row style={{ minHeight: "100%", marginTop: 10, maxHeight: "90vh", overflow: "auto" }}>
        {customerSummary?.projects?.map((project) => (
          <Col key={project.id} md={4} className="mb-4">
            <Card style={{ boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)" }}>
              <Card.Img
                variant="top"
                src={project.logoUrl + sasToken}
                alt={project.name}
                style={{
                  margin: 10,
                  marginLeft: 15,
                  borderRadius: "3px",
                  width: imageSize,
                  height: imageSize,
                }}
              />
              <Card.Body>
                <Card.Title>{project.name}</Card.Title>
                <Card.Text>
                  Aktif güzergah sayısı: <b>{project.routes?.length}</b>
                </Card.Text>
                <Card.Text>
                  <Button
                    style={{ padding: 0 }}
                    variant="link"
                    onClick={(e) => {
                      setSelectedProjectName(project?.name);
                      setSelectedProjectId(project?.id);
                      setSelectedVehicles(
                        project.routes
                          ?.sort((a, b) => {
                            return a?.name?.localeCompare(b?.name);
                          })
                          ?.map((route) => ({
                            ...route,
                            ...customerSummary?.vehicles[route?.vehicleId],
                          }))
                      );
                      
                      showModal(true);
                    }}
                  >
                    Güzergah listesi
                  </Button>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
      <RouteModal
        projectName={selectedProjectName}
        showDocModal={showDocModal}
        show={show}
        handleClose={(e) => showModal(false)}
        vehicles={selectedVehicles}
        handlePassengerListChange={handlePassengerListChange}
        projectId={selectedProjectId}
      />
      <DocumentModal
        projectName={selectedProjectName}
        show={docModal}
        handleClose={(e) => showDocModal(false)}
        vehicles={selectedVehicles}
      />
    </>
  );
};
