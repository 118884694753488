import 'mapbox-gl/dist/mapbox-gl.css';
import "react-datepicker/dist/react-datepicker.css";

import { Button, ButtonGroup, Offcanvas, Card, Col, Dropdown, Form, Row } from 'react-bootstrap';
import React, { useEffect, useRef, useState } from 'react';

import DatePicker from 'react-datepicker';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MainContext } from '../App';
import apiService from "../api";
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { useLocation } from 'react-router-dom';

const days = ['Pt', 'Sa', 'Ça', 'Pe', 'Cu', 'Ct', 'Pz']
const months = ['Ocak', 'Şubat', 'Mart', 'Nisan', 'Mayıs', 'Haziran', 'Temmuz', 'Ağustos', 'Eylül', 'Ekim', 'Kasım', 'Aralık']

const locale = {
  localize: {
    day: n => days[n],
    month: n => months[n]
  },
  formatLong: {
    date: () => 'mm/dd/yyyy'
  }
}

var mapboxgl = require('mapbox-gl/dist/mapbox-gl.js');

const size = 100;

export const monthNameMap = [
  "Ocak",
  "Şubat",
  "Mart",
  "Nisan",
  "Mayıs",
  "Haziran",
  "Temmuz",
  "Ağustos",
  "Eylül",
  "Ekim",
  "Kasım",
  "Aralık"
];

export const hourDateOptions = [
  <option key="1">0</option>,
  <option key="2">1</option>,
  <option key="3">2</option>,
  <option key="4">3</option>,
  <option key="5">4</option>,
  <option key="6">5</option>,
  <option key="7">6</option>,
  <option key="8">7</option>,
  <option key="9">8</option>,
  <option key="10">9</option>,
  <option key="11">10</option>,
  <option key="12">11</option>,
  <option key="13">12</option>,
  <option key="14">13</option>,
  <option key="15">14</option>,
  <option key="16">15</option>,
  <option key="17">16</option>,
  <option key="18">17</option>,
  <option key="19">18</option>,
  <option key="20">19</option>,
  <option key="21">20</option>,
  <option key="22">21</option>,
  <option key="23">22</option>,
  <option key="24">23</option>
]

export const monthDateOptions = [
  <option key="1" value="0">{monthNameMap[0]}</option>,
  <option key="2" value="1">{monthNameMap[1]}</option>,
  <option key="3" value="2">{monthNameMap[2]}</option>,
  <option key="4" value="3">{monthNameMap[3]}</option>,
  <option key="5" value="4">{monthNameMap[4]}</option>,
  <option key="6" value="5">{monthNameMap[5]}</option>,
  <option key="7" value="6">{monthNameMap[6]}</option>,
  <option key="8" value="7">{monthNameMap[7]}</option>,
  <option key="9" value="8">{monthNameMap[8]}</option>,
  <option key="10" value="9">{monthNameMap[9]}</option>,
  <option key="11" value="10">{monthNameMap[10]}</option>,
  <option key="12" value="11">{monthNameMap[11]}</option>
]

export const yearDateOptions = [
  <option key="1">2023</option>,
  <option key="2">2024</option>,
  <option key="3">2025</option>,
  <option key="4">2026</option>,
  <option key="5">2027</option>,
]

const getDaysInMonth = (month) => {
  const year = new Date().getFullYear();
  return new Date(year, month + 1, 0).getDate();
};

const CustomerVehicleDetail = () => {
  const mapRef = useRef();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const details = JSON.parse(queryParams.get('details'));
  
  const { accessToken, setSasToken, setProgress } = React.useContext(MainContext);
  useEffect(() => {
    if (!accessToken)
      return;
      
    apiService.getWithFirstResolve('acquireSasTokenForDocs', accessToken, setProgress)
    .then(data => {
      setSasToken(data.headers.get('Sas-Token'));
      return data.json();
    })
    .then(data => (data));
  }, [])

  const [vehicleHistory, setVehicleHistory] = useState(null);
  const now = new Date();
  let yesterday = new Date();
  yesterday.setDate(now.getDate() - 1);
  let twoDaysAgo = new Date();
  twoDaysAgo.setDate(now.getDate() - 2);
  let fourDaysAgo = new Date();
  fourDaysAgo.setDate(now.getDate() - 4);

  let oneDayAfterFourDaysAgo = new Date();
  oneDayAfterFourDaysAgo.setDate(now.getDate() - 3);
  let oneDayAfterTwoDaysAgo = new Date();
  oneDayAfterTwoDaysAgo.setDate(now.getDate() - 1);

  const [startDate, setStartDate] = useState(yesterday);
  const [startTime, setStartTime] = useState(() => {
    const date = new Date();
    date.setUTCHours(5, 0, 0, 0); // 8 AM in Turkey is 5 AM UTC
    return date.toTimeString().slice(0, 5); // "08:00"
  });
  const [finishDate, setFinishDate] = useState(now);
  const [finishTime, setFinishTime] = useState(now.getHours()?.toString()?.padStart(2, '0') + ":" + now.getMinutes()?.toString()?.padStart(2, '0'));

  const [showSidebar, setShowSidebar] = useState(false);

  const handleCloseSidebar = () => setShowSidebar(false);
  const handleShowSidebar = () => setShowSidebar(true);

  const handleHistoryFilter = () => {
    if (details?.lastLocation != undefined)
    {
      const threeYearsAgo = new Date();
      threeYearsAgo.setFullYear(threeYearsAgo.getFullYear() - 3);

      if (startDate === null || startDate === undefined) {
        alert("Başlangıç tarihi seçilmedi.");
        return;
      }

      if (finishDate === null || finishDate === undefined) {
        alert("Bitiş tarihi seçilmedi.");
        return;
      }
      if (startTime === null || startTime === undefined || startTime === "") {
        alert("Başlangıç saati seçilmedi.");
        return;
      }

      if (finishTime === null || finishTime === undefined || finishTime === "") {
        alert("Bitiş saati seçilmedi.");
        return;
      }


      // Check if startDate is earlier than 3 years ago
      if (new Date(startDate) < threeYearsAgo) {
        alert("Başlangıç tarihi 3 yıldan erken olamaz.");
        return;
      }

      // Tue Aug 13 2024 11:15:52 GMT-0700 (Pacific Daylight Time)
      // 11:15
      const fromDate = new Date(startDate);
      const [hours, minutes] = startTime.split(':').map(Number);
      fromDate.setHours(hours, minutes, 0, 0);
      const fromDateISOString = fromDate.toISOString();

      const untilDate = new Date(finishDate);
      const [hours2, minutes2] = finishTime.split(':').map(Number);
      untilDate.setHours(hours2, minutes2, 0, 0);
      const untilDateISOString = untilDate.toISOString();

      apiService.getWithFirstResolve(`vehicle/${details?.lastLocation?.vehicle?._id}/travelHistory?from=${fromDateISOString}&until=${untilDateISOString}`, accessToken, setProgress)
        .then(data => data.json())
        .then(data => {data.message ? alert(data.message) : setVehicleHistory(data);showSidebar && handleCloseSidebar()})
        .catch(err => alert(err.message));
    }
  }

  const pulsingDot = {
    width: size,
    height: size,
    data: new Uint8Array(size * size * 4),
  
    onAdd: function () {
      const canvas = document.createElement('canvas');
      canvas.width = this.width;
      canvas.height = this.height;
      this.context = canvas.getContext('2d');
    },
  
    render: function () {
      const duration = 1000;
      const t = (performance.now() % duration) / duration;
  
      const radius = (size / 2) * 0.3;
      const outerRadius = (size / 2) * 0.7 * t + radius;
      const context = this.context;
  
      context.clearRect(0, 0, this.width, this.height);
      context.beginPath();
      context.arc(
        this.width / 2,
        this.height / 2,
        outerRadius,
        0,
        Math.PI * 2
      );
      context.fillStyle = `rgba(255, 200, 200, ${1 - t})`;
      context.fill();
  
      context.beginPath();
      context.arc(this.width / 2, this.height / 2, radius, 0, Math.PI * 2);
      context.fillStyle = 'rgba(255, 100, 100, 1)';
      context.strokeStyle = 'white';
      context.lineWidth = 2 + 4 * (1 - t);
      context.fill();
      context.stroke();
  
      this.data = context.getImageData(0, 0, this.width, this.height).data;
  
      mapRef.current.triggerRepaint();
  
      return true;
    }
  };

  const handleDocForm = (event) => {
    event.preventDefault();
  };

  useEffect(() => {
    // Check if details.plate exists and set the document title accordingly
    if (details && details["plate"]) {
      document.title = `${details["plate"]}`;
    }
  }, [details]);

  useEffect(() => {
    if (details?.lastLocation != undefined)
    {
      const fromDate = new Date(startDate);
      const [hours, minutes] = startTime.split(':').map(Number);
      fromDate.setHours(hours, minutes, 0, 0);
      const fromDateISOString = fromDate.toISOString();

      const untilDate = new Date(finishDate);
      const [hours2, minutes2] = finishTime.split(':').map(Number);
      untilDate.setHours(hours2, minutes2, 0, 0);
      const untilDateISOString = untilDate.toISOString();

      apiService.getWithFirstResolve(`vehicle/${details?.lastLocation?.vehicle?._id}/travelHistory?from=${fromDateISOString}&until=${untilDateISOString}`, accessToken, setProgress)
        .then(data => data.json())
        .then(data => setVehicleHistory(data));
    }
  }, []);

  useEffect(() => {
    mapboxgl.accessToken = 'pk.eyJ1IjoiZW1za2FwbGFubiIsImEiOiJjbHplZHZ0dnkwcHN0MmpwbjcwdHhwNzQyIn0.s--gDj5BIX6b0ll_AB3TIg';
    mapRef.current = new mapboxgl.Map({
      container: 'mapbox-parent',
      center: [29.032669, 41.032921],
      zoom: 10
    });
    mapRef.current.addImage('pulsing-dot', Object.assign({}, pulsingDot), { pixelRatio: 2 });
  }, []);

  useEffect(() => {
    if (mapRef.current && vehicleHistory?.length > 0) {
      const coordinates = vehicleHistory.map(entry => [entry?.location?.lon, entry?.location?.lat]);

      if (mapRef.current.getSource('route')) {
        mapRef.current.getSource('route').setData({
          'type': 'Feature',
          'properties': {},
          'geometry': {
            'type': 'LineString',
            'coordinates': coordinates
          }
        });
      } else {
        mapRef.current.addSource('route', {
          'type': 'geojson',
          'data': {
            'type': 'Feature',
            'properties': {},
            'geometry': {
              'type': 'LineString',
              'coordinates': coordinates
            }
          }
        });

        mapRef.current.addLayer({
          'id': 'route',
          'type': 'line',
          'source': 'route',
          'layout': {
            'line-join': 'round',
            'line-cap': 'round'
          },
          'paint': {
            'line-color': '#3151f5',
            'line-width': 6
          }
        });
      }

      if (mapRef.current.getSource('dot-point')) {
        mapRef.current.getSource('dot-point').setData({
          type: 'FeatureCollection',
          features: [
            {
              type: 'Feature',
              geometry: {
                type: 'Point',
                coordinates: coordinates[coordinates?.length - 1]
              }
            }
          ]
        });
      } else {
        mapRef.current.addSource('dot-point', {
          type: 'geojson',
          data: {
            type: 'FeatureCollection',
            features: [
              {
                type: 'Feature',
                geometry: {
                  type: 'Point',
                  coordinates: coordinates[coordinates?.length - 1]
                }
              }
            ]
          }
        });

        mapRef.current.addLayer({
          id: 'layer-with-pulsing-dot',
          type: 'symbol',
          source: 'dot-point',
          layout: {
            'icon-image': 'pulsing-dot'
          }
        });
      }
    }

    if (vehicleHistory?.length > 0) {
      const timestamp = vehicleHistory[vehicleHistory.length - 1]?.ts;

      if (timestamp) {
        // Create a new Date object from the timestamp
        const date = new Date(timestamp);
        setFinishDate(date);
        setFinishTime(date.getHours().toString().padStart(2, '0') + ":" + date.getMinutes().toString().padStart(2, '0'));
      }
    }
  }, [vehicleHistory]);

  return (
    <div style={{height: '100%'}}>
      <Offcanvas show={showSidebar} onHide={handleCloseSidebar}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Ayarlar</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          {/* Add your form controls here */}
          <Row>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <span style={{ fontSize: '16px', fontWeight: 500, marginRight: 5 }}>Plaka: </span>
              <Button variant="link" className="tableFont" style={{ padding: 0, fontSize: '16px' }} onClick={() => {
                let doc = window.open(`http://${window.location.host}/vehicleDetailPart?details=${JSON.stringify(details)}`, `${details.plate}`, 'width=800,height=600');
              }}>{details.plate}</Button>
            </div>

            <div style={{ display: 'flex', alignItems: 'center' }}>
              <span style={{ fontSize: '16px', fontWeight: 500, marginRight: 5 }}>Sürücü adı: </span>
              <Button variant="link" className="tableFont" style={{ padding: 0, fontSize: '16px' }} onClick={() => {
                let doc = window.open(`http://${window.location.host}/driverDetail?details=${JSON.stringify(details)}`, `${details.plate}`, 'width=800,height=500');
              }}>{details["driverName"] ?? "<Bilinmeyen>"}</Button>
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <span style={{ fontSize: '16px', fontWeight: 600, marginRight: 5 }}>Tel no: </span>
              <a href={`tel:+90${details["driverPhone"] ?? "<Bilinmeyen>"}`}>{details["driverPhone"] ?? "<Bilinmeyen>"}</a>
              <FontAwesomeIcon
                className="copy-icon"
                icon={faCopy}
                color="black"
                style={{ marginLeft: '5px', cursor: 'pointer' }}
                onClick={() => navigator.clipboard.writeText(`+90${details.driverPhone}`)}
              />
            </div>
          </Row>
          <Row style={{ marginTop: 10 }}>
            <span style={{ fontSize: '16px', fontWeight: 500 }}>Saat & tarih:</span>
            <Col>
              <Row>
                <div className="d-flex justify-content-end align-items-center">
                  <input style={{ marginRight: 10, display: 'inline-block', width: 'auto' }} className="form-control" value={startTime} onChange={(time) => setStartTime(time.target.value)} type="time" />
                  <DatePicker className="form-control me-2" selected={startDate} onChange={(date) => setStartDate(date)} locale={locale} />
                </div>
              </Row>
              <Row style={{ marginTop: 10 }}>
                <div className="d-flex justify-content-end align-items-center">
                  <input style={{ marginRight: 10, display: 'inline-block', width: 'auto' }} className="form-control" value={finishTime} onChange={(time) => setFinishTime(time.target.value)} type="time" />
                  <DatePicker className="form-control me-2" selected={finishDate} onChange={(date) => setFinishDate(date)} locale={locale} />
                </div>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col>
              <Col xs={2} style={{ marginTop: 10, width: '100%' }} className="d-flex justify-content-end">
                <Button style={{ marginLeft: 5, fontSize: '13px', whiteSpace: 'nowrap' }} variant="secondary" disabled={details?.lastLocation === undefined} onClick={e => { setStartDate(fourDaysAgo); }}>
                  4 gün önce
                </Button>
                <Button style={{ marginLeft: 5, fontSize: '13px', whiteSpace: 'nowrap' }} variant="secondary" disabled={details?.lastLocation === undefined} onClick={e => { setStartDate(twoDaysAgo); }}>
                  2 gün önce
                </Button>
                <Button style={{ marginLeft: 5, fontSize: '13px', whiteSpace: 'nowrap' }} disabled={details?.lastLocation === undefined} onClick={e => handleHistoryFilter()}>
                  Sorgula
                </Button>
              </Col>
            </Col>
          </Row>
        </Offcanvas.Body>
      </Offcanvas>

      <div style={{ height: '100%', display: 'flex' }}>
        <div id="left-pane" style={{ width: '300px', backgroundColor: '#f0f0f0', padding: '10px' }}>
        <Row>
            <h4>Araç ve Sürücü Bilgileri</h4>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <span style={{ fontSize: '16px', fontWeight: 500, marginRight: 5 }}>Plaka: </span>
              <Button variant="link" className="tableFont" style={{ padding: 0 }} onClick={() => {
                let doc = window.open(`http://${window.location.host}/vehicleDetailPart?details=${JSON.stringify(details)}`, `${details.plate}`, 'width=800,height=600');
              }}>{details.plate}</Button>
            </div>

            <div style={{ display: 'flex', alignItems: 'center' }}>
              <span style={{ fontSize: '16px', fontWeight: 500, marginRight: 5 }}>Sürücü adı: </span>
              <Button variant="link" className="tableFont" style={{ padding: 0 }} onClick={() => {
                let doc = window.open(`http://${window.location.host}/driverDetail?details=${JSON.stringify(details)}`, `${details.plate}`, 'width=800,height=500');
              }}>{details["driverName"] ?? "<Bilinmeyen>"}</Button>
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <span style={{ fontSize: '16px', fontWeight: 600, marginRight: 5 }}>Tel no: </span>
              <a href={`tel:+90${details["driverPhone"] ?? "<Bilinmeyen>"}`}>{details["driverPhone"] ?? "<Bilinmeyen>"}</a>
              <FontAwesomeIcon
                className="copy-icon"
                icon={faCopy}
                color="black"
                style={{ marginLeft: '5px', cursor: 'pointer' }}
                onClick={() => navigator.clipboard.writeText(`+90${details.driverPhone}`)}
              />
            </div>
          </Row>
          <Row style={{ marginTop: 10 }}>
            <span style={{ fontSize: '16px', fontWeight: 500 }}>Saat & tarih:</span>
            <Col>
              <Row>
                <div className="d-flex justify-content-end align-items-center">
                  <input style={{ marginRight: 10, display: 'inline-block', width: 'auto' }} className="form-control" value={startTime} onChange={(time) => setStartTime(time.target.value)} type="time" />
                  <DatePicker className="form-control me-2" selected={startDate} onChange={(date) => setStartDate(date)} locale={locale} />
                </div>
              </Row>
              <Row style={{ marginTop: 10 }}>
                <div className="d-flex justify-content-end align-items-center">
                  <input style={{ marginRight: 10, display: 'inline-block', width: 'auto' }} className="form-control" value={finishTime} onChange={(time) => setFinishTime(time.target.value)} type="time" />
                  <DatePicker className="form-control me-2" selected={finishDate} onChange={(date) => setFinishDate(date)} locale={locale} />
                </div>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col>
              <Col xs={2} style={{ marginTop: 10, width: '100%' }} className="d-flex justify-content-end">
                <Button style={{ marginLeft: 5, fontSize: '13px', whiteSpace: 'nowrap' }} variant="secondary" disabled={details?.lastLocation === undefined} onClick={e => { setStartDate(fourDaysAgo); setFinishDate(oneDayAfterFourDaysAgo); }}>
                  4 gün önce
                </Button>
                <Button style={{ marginLeft: 5, fontSize: '13px', whiteSpace: 'nowrap' }} variant="secondary" disabled={details?.lastLocation === undefined} onClick={e => { setStartDate(twoDaysAgo); setFinishDate(oneDayAfterTwoDaysAgo); }}>
                  2 gün önce
                </Button>
                <Button style={{ marginLeft: 5, fontSize: '13px', whiteSpace: 'nowrap' }} disabled={details?.lastLocation === undefined} onClick={e => handleHistoryFilter()}>
                  Sorgula
                </Button>
              </Col>
            </Col>
          </Row>
        </div>
        <div id="mapbox-parent" style={{ flex: '1 1 auto', overflow: 'hidden', position: 'relative' }}>
          {details?.lastLocation === undefined && (
            <div style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              backgroundColor: 'rgba(255, 255, 255, 0.8)',
              padding: '20px',
              borderRadius: '10px',
              zIndex: 1,
              textAlign: 'center',
              fontSize: '22px',
              fontWeight: 'bold'
            }}>
              Araca ait GPS kaydı bulunamadı, yetkili birimlerle iletişime geçiniz.
            </div>
          )}
          <div style={{zIndex: 4, position: 'relative'}}>
          <Button style={{ margin: '10px' }} variant="primary" id="open-sidebar-button" onClick={handleShowSidebar}>
            Ayarlar
          </Button>
          </div>
          
          {/* Mapbox content */}
        </div>
      </div>
      <style jsx>{`
        @media (min-width: 768px) {
          #open-sidebar-button {
            display: none;
          }
          #left-pane {
            display: block;
          }
        }
          @media (max-width: 768px) {
          #open-sidebar-button {
            display: block;
          }
          #left-pane {
            display: none;
          }
        }
      `}</style>
    </div>
  );
};

export default CustomerVehicleDetail;