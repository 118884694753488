// VehicleModal.js
import React, { useEffect, useContext } from 'react';
import { Modal, Table, Button, Row, Col, Card, Form } from 'react-bootstrap';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as XLSX from 'xlsx-js-style';
import { useState } from 'react';
import { createPath } from 'react-router-dom';
import { MainContext } from '../App';
export const DocumentModal = ({ projectName, show, handleClose, vehicles }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [rows, setRows] = useState([]);
  const [exRows, setExcelRows] = useState([]);
  const { signedInUserEmail } = useContext(MainContext);

  const exportToExcel = () => {
    if (exRows.length === 0) return;

    const newWs = XLSX.utils.aoa_to_sheet([]);
    const title = `${projectName} Toplu Evrak Listesi`;
    XLSX.utils.sheet_add_aoa(newWs, [[title]], { origin: 'A1' });
    XLSX.utils.sheet_add_aoa(newWs, [[""]], { origin: 'A2' });
    newWs['A2'].s = {
        fill: {
            fgColor: { rgb: "ed545c" } // Yellow background
        },
    };
    
    XLSX.utils.sheet_add_json(newWs, exRows?.map(row => ({
      'Güzergah': row.name,
      'Plaka': row.plate,
      'Ehliyet': row.ehliyet,
      'Psikoteknik Belgesi': row.psikoTeknik,
      'Sabıka Kaydı': row.sabikaKaydi,
      'Sağlık Raporu': row.saglikRaporu,
      'SRC2 Belgesi': row.srcTwo,
      'Muayene Belgesi': row.muayene,
      'Ruhsat': row.ruhsat,
      'Koltuk Sigorta Belgesi': row.koltukSigortasi,
      'Trafik Sigorta Belgesi': row.trafikSigortasi,
      'Risk analizi': row.riskAnalysis,
      'ISG sertifikası': row.isgCertificate,
      'Kimlik': row.idCard,
      'Acil eylem kaçış planı': row.emergencyEvacuationPlan,
      'Toplu taşıma kullanım belgesi': row.publicTransportationPermit
    })), {origin: 'A3'});
    
    // Merge cells for the title
    const merge = [
      { s: { r: 0, c: 0 }, e: { r: 0, c: 15 } }
    ];
    if (!newWs['!merges']) newWs['!merges'] = [];
    newWs['!merges'] = newWs['!merges'].concat(merge);

    const merge3 = [
        { s: { r: 1, c: 0 }, e: { r: 1, c: 15 } }
    ];
    if (!newWs['!merges']) newWs['!merges'] = [];
    newWs['!merges'] = newWs['!merges'].concat(merge3);
    
    // Apply styling to the title
    newWs['A1'].s = {
        font: {
            name: 'Arial',
            sz: 20, // Increase the font size
            bold: true,
            color: { rgb: "FFFFFF" } // White text
        },
        fill: {
            fgColor: { rgb: "14345c" } // Black background
        },
        alignment: {
            horizontal: 'center',
            vertical: 'center'
        }
    };

    const wStyle = {
        font: {
            name: 'Arial',
            sz: 10,
            color: { rgb: "ffffff" }
        },
        fill: {
            fgColor: { rgb: "43648c" } // Yellow background
        },
        alignment: {
            horizontal: 'center',
            vertical: 'center'
        },
        border: {
            top: {
                style: 'medium',
                color: { rgb: "000000" }
            },
            bottom: {
                style: 'medium',
                color: { rgb: "000000" }
            },
            left: {
                style: 'medium',
                color: { rgb: "000000" }
            },
            right: {
                style: 'medium',
                color: { rgb: "000000" }
            }
        }
    };
    
    const wStyleH = {
      font: {
          name: 'Arial',
          sz: 10,
          color: { rgb: "ffffff" }
      },
      fill: {
          fgColor: { rgb: "14345c" } // Yellow background
      },
      alignment: {
          horizontal: 'center',
          vertical: 'center'
      },
      border: {
          top: {
              style: 'medium',
              color: { rgb: "000000" }
          },
          bottom: {
              style: 'medium',
              color: { rgb: "000000" }
          },
          left: {
              style: 'medium',
              color: { rgb: "000000" }
          },
          right: {
              style: 'medium',
              color: { rgb: "000000" }
          }
      }
    };

    const wEStyle = {
      font: {
          name: 'Arial',
          sz: 10,
          color: { rgb: "ffffff" }
      },
      fill: {
          fgColor: { rgb: "eb7f84" } // Yellow background
      },
      alignment: {
          horizontal: 'center',
          vertical: 'center'
      },
      border: {
          top: {
              style: 'medium',
              color: { rgb: "000000" }
          },
          bottom: {
              style: 'medium',
              color: { rgb: "000000" }
          },
          left: {
              style: 'medium',
              color: { rgb: "000000" }
          },
          right: {
              style: 'medium',
              color: { rgb: "000000" }
          }
      }
  };

    const paint = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P'];
    paint.forEach((el) => {
        if (!newWs[`${el}3`]) newWs[`${el}3`] = {}; 
        newWs[`${el}3`].s = wStyleH;
        
        for (let i = 4; i < vehicles.length + 4; i++) {
            if (!newWs[`${el}${i}`]) newWs[`${el}${i}`] = {}; 
            if (newWs[`${el}${i}`].v != 'Aktif' && el != 'A' && el != 'B') {
              newWs[`${el}${i}`].s = wEStyle;
            } else {
              newWs[`${el}${i}`].s = wStyle;
            }

            if (el === 'A' || el === 'B')
            {
                newWs[`${el}${i}`].s = {...wStyle, alignment: { horizontal: 'left', vertical: 'center' }};
            }
        }
    });

    var wscols = [
      {wch:35},
      {wch:20},
      {wch:12},
      {wch:12},
      {wch:12},
      {wch:12},
      {wch:12},
      {wch:12},
      {wch:12},
      {wch:12},
      {wch:12}
    ];

    newWs['!cols'] = wscols;

    const createdByRow = vehicles.length + 4;
    const createdBy = `${signedInUserEmail ?? '-'} tarafından ${new Date().toLocaleDateString()} tarihinde oluşturuldu.`;
    
    XLSX.utils.sheet_add_aoa(newWs, [[createdBy]], { origin: `A${createdByRow}` });

    newWs[`A${createdByRow}`].s = {
        font: {
            name: 'Arial',
            sz: 11, // Increase the font size
            bold: true,
            color: { rgb: "FFFFFF" } // White text
        },
        fill: {
            fgColor: { rgb: "000000" } // Black background
        },
        alignment: {
            horizontal: 'center',
            vertical: 'center'
        }
    };

    const merge2 = [
      { s: { r: createdByRow - 1, c: 0 }, e: { r: createdByRow - 1, c: 15 } } // Merging cells A11, B11, and C11
    ];
    if (!newWs['!merges']) newWs['!merges'] = [];
    newWs['!merges'] = newWs['!merges'].concat(merge2);

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, newWs, 'Ana sayfa');
    XLSX.writeFile(wb, `${projectName}-toplu-evrak-listesi.xlsx`);
  };

  const fileKeys = [
    "ehliyet",
    "psikoTeknik",
    "sabikaKaydi",
    "saglikRaporu",
    "srcTwo",
    "muayene",
    "ruhsat",
    "koltukSigortasi",
    "trafikSigortasi",
    "riskAnalysis",
    "isgCertificate",
    "idCard",
    "emergencyEvacuationPlan",
    "publicTransportationPermit"
  ]

  const typeMapping = {
    "muayene": "Muayene belgesi",
    "ruhsat": "Ruhsat",
    "koltukSigortasi": "Koltuk sigorta belgesi",
    "trafikSigortasi": "Trafik sigorta belgesi",
    "ehliyet": "Ehliyet",
    "psikoTeknik": "Psikoteknik belgesi",
    "sabikaKaydi": "Sabıka kaydı",
    "saglikRaporu": "Sağlık raporu",
    "srcTwo": "SRC2 belgesi",
    "riskAnalysis": "Risk analizi",
    "isgCertificate": 'ISG sertifikası',
    "idCard": "Kimlik",
    "emergencyEvacuationPlan": "Acil eylem kaçış planı",
    "publicTransportationPermit": "Toplu taşıma kullanım belgesi"
  }

  const dateDifferenceInDays = (date1, date2) => {
    const oneDay = 24 * 60 * 60 * 1000; // milliseconds in one day
    const diffInTime = date1.getTime() - date2.getTime();
    const diffInDays = Math.round(diffInTime / oneDay);
    return diffInDays;
  };
  
  const generateDocs = () => {
    try {
      let rows = [];
      vehicles?.filter(el => `${el?.plate} / ${el?.name}`.toLocaleLowerCase('tr')?.includes(searchTerm)).forEach(el => {
          let cols = {}
          fileKeys.forEach(key => {
              cols['plate'] = el.plate;
              cols['name'] = el.name;
              cols['id'] = `${el.plate} / ${el.name}`
              if (!el[key]) {
                  cols[key] = "Eksik"
              } else {
                let prefixLen = "https://kentistturizmdocs.blob.core.windows.net/docs/".length;
                let fileExpiryDateSplit = el[key]?.slice(prefixLen, -1)?.split('-')[0].split('.');
                if (fileExpiryDateSplit.length === 3)
                {
                    let year = fileExpiryDateSplit[2];
                    let month = fileExpiryDateSplit[1] - 1;
                    let day = fileExpiryDateSplit[0];
                    let date = new Date(year, month, day)
                    
                    let now = new Date()
                    let aMonthLater = new Date()
                    aMonthLater.setMonth(aMonthLater.getMonth() + 1)
                    
                    if (date < aMonthLater)
                    {
                        cols[key] = `${dateDifferenceInDays(date, now)} gün`
                        return;
                    }
                }
                cols[key] = "Aktif"
              }
          });
          rows.push(cols)
      })

      setExcelRows(rows)
      let transRows = rows?.map(row => {
          return (
              <tr key={row.id}>
                  <td key={row.id}>{row.name}</td>
                  <td key={row.id}>{row.plate}</td>
                  {fileKeys.map(key => {
                      return <td style={{backgroundColor: row[key] !== "Aktif" ? row[key] === "Eksik" ? "#ffabc3" : "#ffebee" : "#d6ffeb"}}>{row[key]}</td>
                  })}
              </tr>
          );
      })
      setRows(transRows)
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    generateDocs()
  }, [vehicles, searchTerm])
  
  return (
    <Modal show={show} onHide={(e) => handleClose()} size="xl">
      <Modal.Header closeButton>
        <Modal.Title>{projectName} Toplu Evrak Listesi</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{height: '75vh', overflowX: 'auto'}}>
      <input
      style={{padding: 5}}
      type="text"
      placeholder="Plaka veya güzergah ara..."
      value={searchTerm}
      onChange={(e) => setSearchTerm(e.target.value)}
      />
      <Button variant="primary" onClick={() => exportToExcel()} style={{ marginLeft: 'auto', float: 'right', marginBottom: 10}}>
          Excel indir
      </Button>
      <Table striped bordered hover style={{tableLayout: 'fixed', marginTop: 10, marginBottom: 10}}>
                                    <colgroup>
                                        <col style={{width: '18%'}} />
                                        <col style={{width: '12%'}} />
                                        <col style={{width: '8%'}} />
                                        <col style={{width: '8%'}} />
                                        <col style={{width: '8%'}} />
                                        <col style={{width: '8%'}} />
                                        <col style={{width: '8%'}} />
                                        <col style={{width: '8%'}} />
                                        <col style={{width: '8%'}} />
                                        <col style={{width: '8%'}} />
                                        <col style={{width: '8%'}} />
                                        <col style={{width: '8%'}} />
                                        <col style={{width: '8%'}} />
                                        <col style={{width: '8%'}} />
                                        <col style={{width: '8%'}} />
                                        <col style={{width: '8%'}} />
                                    </colgroup>
                                    <thead>
                                        <tr style={{fontSize: '0.7vh'}}>
                                        <th>Güzergah</th>
                                          <th>Plaka</th>
                                          {fileKeys.map(el => {
                                              return <th>{typeMapping[el]}</th>
                                          })}
                                        </tr>
                                    </thead>
                                    <tbody className='default-table'>
                                        {rows}
                                    </tbody>
                                    </Table>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Kapat
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
