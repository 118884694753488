import { Card, Col, Container, Row } from "react-bootstrap";
import {
  faAddressCard,
  faCarBurst,
  faMagnifyingGlass,
  faPersonRunning,
  faTableList,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MainContext } from "../App";
import React from "react";
import apiService from "../api";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export const CustomerVehicleDetailPart = (props) => {
  const typeMapping = {
    muayene: "Muayene belgesi",
    ruhsat: "Ruhsat",
    koltukSigortasi: "Koltuk sigorta belgesi",
    trafikSigortasi: "Trafik sigorta belgesi",
    riskAnalysis: "Risk analizi",
    emergencyEvacuationPlan: "Acil eylem kaçış planı",
  };

  const { accessToken, setSasToken, sasToken, setProgress } = React.useContext(MainContext);
  useEffect(() => {
    if (!accessToken)
      return;
      
    apiService.getWithFirstResolve('acquireSasTokenForDocs', accessToken, setProgress)
    .then(data => {
      setSasToken(data.headers.get('Sas-Token'));
      return data.json();
    })
    .then(data => (data));
  }, [])
  
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const details = JSON.parse(queryParams.get("details"));
  const anyVehicleDoc = Object.keys(details).some((key) => key in typeMapping);
  const iconMapping = {
    muayene: faMagnifyingGlass,
    ruhsat: faAddressCard,
    koltukSigortasi: faUsers,
    trafikSigortasi: faCarBurst,
    riskAnalysis: faTableList,
    emergencyEvacuationPlan: faPersonRunning,
  };

  const generateVehicleDocs = () => {
    return Object.keys(details).map((key) => {
      if (key in typeMapping) {
        // return <Card.Text key={key}>{typeMapping[key]}: {details[key]}</Card.Text>
        return (
          <Col
            style={{ marginTop: 10 }}
            xs={4}
            onClick={(e) =>
              window.open(
                details[key] + sasToken,
                details[key],
                "width:550px;height:550px"
              )
            }
          >
            <Card style={{ cursor: "pointer", padding: 3 }}>
              <Card.Body>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    height: "150px",
                    alignItems: "center",
                    justifyContent: "center",
                    textAlign: "center",
                  }}
                >
                  <div>
                    <FontAwesomeIcon
                      color="black"
                      size="2x"
                      icon={iconMapping[key]}
                    ></FontAwesomeIcon>
                  </div>
                  <span style={{ fontWeight: 500, fontSize: "18px" }}>
                    {typeMapping[key]}
                  </span>
                </div>
              </Card.Body>
            </Card>
          </Col>
        );
      }
      return null;
    });
  };

  return (
    <Container>
      <Card style={{ width: "100%" }}>
        <Card.Body>
          <Card.Title style={{ textAlign: "center" }}>
            Araç evrakları - {details.plate ?? "(-)"}
          </Card.Title>
          {anyVehicleDoc ? (
            <Row>{generateVehicleDocs()}</Row>
          ) : (
            <Card.Text>
              <u>Araca ait evrak bulunamadı.</u>
            </Card.Text>
          )}
        </Card.Body>
      </Card>
    </Container>
  );
};
