import * as XLSX from "xlsx-js-style";

import { Button, Card, Col, Form, Modal, Row, Table } from "react-bootstrap";
// VehicleModal.js
import React, { useContext, useEffect } from "react";
import {
  faCircleCheck,
  faCircleXmark,
  faCopy,
  faEdit,
} from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MainContext } from "../App"
import PassengerListModal from "./PassengerListModal";
import { useState } from "react";

export const RouteModal = ({
  projectName,
  show,
  handleClose,
  vehicles,
  showDocModal,
  handlePassengerListChange,
  projectId
}) => {
  const { signedInUserEmail } = useContext(MainContext);
  const [searchTerm, setSearchTerm] = useState("");
  const exportToExcel = () => {
    if (vehicles.length === 0) return;

    const newWs = XLSX.utils.aoa_to_sheet([]);
    const title = `${projectName} Güzergah Listesi`;
    XLSX.utils.sheet_add_aoa(newWs, [[title]], { origin: "A1" });
    XLSX.utils.sheet_add_aoa(newWs, [[""]], { origin: "A2" });
    newWs["A2"].s = {
      fill: {
        fgColor: { rgb: "ed545c" }, // Yellow background
      },
    };

    const links1 = [];
    const links2 = [];
    const links3 = [];
    XLSX.utils.sheet_add_json(
      newWs,
      vehicles?.map((vehicle) => {
        links1.push(
          `https://${
            window.location.host
          }/driverDetail?details=${encodeURIComponent(JSON.stringify({...vehicle, passengerList: undefined}))}`
        );
        links2.push(
          `https://${
            window.location.host
          }/vehicleDetailPart?details=${encodeURIComponent(
            JSON.stringify({...vehicle, passengerList: undefined})
          )}`
        );
        links3.push(
          `https://${window.location.host}/vehicleDetail/${
            vehicle.vehicleId
          }?details=${encodeURIComponent(JSON.stringify({...vehicle, passengerList: undefined}))}`
        );
        return {
          Güzergah: vehicle.name ?? '-',
          Sürücü: vehicle.driverName ?? '-',
          "Tel no.": vehicle.driverPhone ?? '-',
          Plaka: vehicle.plate ?? '-',
          "GPS Link": `Tıkla ${vehicle?.lastLocation ? "✔" : "✖"}`,
        };
      }),
      { origin: "A3" }
    );

    // Merge cells for the title
    const merge = [{ s: { r: 0, c: 0 }, e: { r: 0, c: 4 } }];
    if (!newWs["!merges"]) newWs["!merges"] = [];
    newWs["!merges"] = newWs["!merges"].concat(merge);

    const merge3 = [{ s: { r: 1, c: 0 }, e: { r: 1, c: 4 } }];
    if (!newWs["!merges"]) newWs["!merges"] = [];
    newWs["!merges"] = newWs["!merges"].concat(merge3);

    // Apply styling to the title
    newWs["A1"].s = {
      font: {
        name: "Arial",
        sz: 20, // Increase the font size
        bold: true,
        color: { rgb: "FFFFFF" }, // White text
      },
      fill: {
        fgColor: { rgb: "14345c" }, // Black background
      },
      alignment: {
        horizontal: "center",
        vertical: "center",
      },
    };

    const wStyle = {
      font: {
        name: "Arial",
        sz: 10,
        color: { rgb: "ffffff" },
      },
      fill: {
        fgColor: { rgb: "43648c" }, // Yellow background
      },
      alignment: {
        horizontal: "center",
        vertical: "center",
      },
      border: {
        top: {
          style: "medium",
          color: { rgb: "000000" },
        },
        bottom: {
          style: "medium",
          color: { rgb: "000000" },
        },
        left: {
          style: "medium",
          color: { rgb: "000000" },
        },
        right: {
          style: "medium",
          color: { rgb: "000000" },
        },
      },
    };

    const wStyleH = {
      font: {
        name: "Arial",
        sz: 10,
        color: { rgb: "ffffff" },
      },
      fill: {
        fgColor: { rgb: "14345c" }, // Yellow background
      },
      alignment: {
        horizontal: "center",
        vertical: "center",
      },
      border: {
        top: {
          style: "medium",
          color: { rgb: "000000" },
        },
        bottom: {
          style: "medium",
          color: { rgb: "000000" },
        },
        left: {
          style: "medium",
          color: { rgb: "000000" },
        },
        right: {
          style: "medium",
          color: { rgb: "000000" },
        },
      },
    };

    const paint = ["A", "B", "C", "D", "E"];
    paint.forEach((el) => {
      if (!newWs[`${el}3`]) newWs[`${el}3`] = {};
      newWs[`${el}3`].s = wStyleH;

      for (let i = 4; i < vehicles.length + 4; i++) {
        if (!newWs[`${el}${i}`]) newWs[`${el}${i}`] = {};
        newWs[`${el}${i}`].s = wStyle;

        if (el === "B") {
          if (!newWs[`${el}${i}`]) newWs[`${el}${i}`] = {};
          newWs[`${el}${i}`].l = {
            Target: links1[i - 4],
            Tooltip: "Sürücü evrakları",
          };
        }

        if (el === "D") {
          if (!newWs[`${el}${i}`]) newWs[`${el}${i}`] = {};
          newWs[`${el}${i}`].l = {
            Target: links2[i - 4],
            Tooltip: "Araç evrakları",
          };
        }

        if (el === "E") {
          if (!newWs[`${el}${i}`]) newWs[`${el}${i}`] = {};
          newWs[`${el}${i}`].l = { Target: links3[i - 4], Tooltip: `GPS` };
        }

        if (el === "A" || el === "B") {
          newWs[`${el}${i}`].s = {
            ...wStyle,
            alignment: { horizontal: "left", vertical: "center" },
          };
        }
      }
    });

    var wscols = [
      { wch: 50 },
      { wch: 35 },
      { wch: 15 },
      { wch: 15 },
      { wch: 20 },
    ];

    newWs["!cols"] = wscols;

    const createdByRow = vehicles.length + 4;
    const createdBy = `${
      signedInUserEmail ?? "-"
    } tarafından ${new Date().toLocaleDateString()} tarihinde oluşturuldu.`;

    XLSX.utils.sheet_add_aoa(newWs, [[createdBy]], {
      origin: `A${createdByRow}`,
    });

    newWs[`A${createdByRow}`].s = {
      font: {
        name: "Arial",
        sz: 11, // Increase the font size
        bold: true,
        color: { rgb: "FFFFFF" }, // White text
      },
      fill: {
        fgColor: { rgb: "000000" }, // Black background
      },
      alignment: {
        horizontal: "center",
        vertical: "center",
      },
    };

    const merge2 = [
      { s: { r: createdByRow - 1, c: 0 }, e: { r: createdByRow - 1, c: 4 } }, // Merging cells A11, B11, and C11
    ];
    if (!newWs["!merges"]) newWs["!merges"] = [];
    newWs["!merges"] = newWs["!merges"].concat(merge2);

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, newWs, "Ana sayfa");
    XLSX.writeFile(wb, `${projectName}-güzergah-listesi.xlsx`);
  };

  const [showPassengerListModal, setShowPassengerListModal] = useState(false);
  const [currentNameList, setCurrentNameList] = useState(null);
  const [currentRoute, setCurrentRoute] = useState(null);

  useEffect(() => {
    setCurrentNameList(vehicles?.find(el => el?._id == currentRoute?._id)?.passengerList);
  }, [vehicles]);

  const handleShowPassengerListModal = (nameList, route) => {
    setCurrentNameList(nameList);
    setCurrentRoute(route);
    setShowPassengerListModal(true);
  };
  
  return (
    <>
    <Modal show={show} onHide={(e) => handleClose()} size="xl">
      <Modal.Header closeButton>
        <Modal.Title>{projectName} Güzergah Listesi</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ height: "75vh", overflowY: "scroll" }}>
        <Row>
        <Col>
        <input
          style={{ padding: 5 }}
          type="text"
          placeholder="Güzergah ara..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        </Col>
        <Col>
        <Button
          variant="primary"
          onClick={exportToExcel}
          style={{ marginLeft: "auto", float: "right", marginBottom: 10 }}
        >
          Excel indir
        </Button>
        </Col>
        </Row>
        <Table
          striped
          bordered
          hover
          style={{ tableLayout: "fixed", marginTop: 10, marginBottom: 10 }}
        >
          <colgroup>
            <col style={{ width: "20%" }} />
            <col style={{ width: "17%" }} />
            <col style={{ width: "15%" }} />
            <col style={{ width: "13%" }} />
            <col style={{ width: "7%" }} />
            <col style={{ width: "7%" }} />
            <col style={{ width: "7%" }} />
          </colgroup>
          <thead>
            <tr style={{ fontSize: "0.7vw" }}>
              <th>Güzergah</th>
              <th>Sürücü</th>
              <th>Tel no.</th>
              <th>Plaka</th>
              <th>Yolcu sayısı</th>
              <th>Koltuk sayısı</th>
              <th>GPS</th>
            </tr>
          </thead>
          <tbody className="default-table">
            {vehicles
              ?.filter((el) =>
                el?.name?.toLocaleLowerCase('tr').includes(searchTerm.toLocaleLowerCase('tr'))
              )
              ?.map((route, idx) => {
                return (
                  <tr key={route.vehicleId + idx}>
                    <td>{route.name}</td>
                    <td>
                      {route.driverName ? (
                        <Button
                          variant="link"
                          className="tableFont"
                          style={{ padding: 0 }}
                          onClick={() => {
                            let doc = window.open(
                              `http://${
                                window.location.host
                              }/driverDetail?details=${JSON.stringify({...route, passengerList: undefined})}`,
                              `${route.driverName}`,
                              "width=900,height=900"
                            );
                            doc.title = route.driverName;
                          }}
                        >
                          <span className="shrink-on-small">{route.driverName}</span>
                        </Button>
                      ) : (
                        <span className="text-muted">
                          {"-"}
                        </span>
                      )}
                    </td>
                    <td>
                      {route.driverPhone ? (
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <a href={`tel:+90${route.driverPhone}`} className="tableFont">
                            {route.driverPhone}
                          </a>
                          <FontAwesomeIcon
                            className="copy-icon"
                            icon={faCopy}
                            color="black"
                            style={{ marginLeft: "10px", cursor: "pointer" }}
                            onClick={() =>
                              navigator.clipboard.writeText(
                                `+90${route.driverPhone}`
                              )
                            }
                          />
                        </div>
                      ) : (
                        <span className="text-muted">
                          {"-"}
                        </span>
                      )}
                    </td>
                    <td>
                      {route.plate ? (
                        <Button
                          variant="link"
                          className="tableFont"
                          style={{ padding: 0 }}
                          onClick={() => {
                            let doc = window.open(
                              `http://${
                                window.location.host
                              }/vehicleDetailPart?details=${JSON.stringify({...route, passengerList: undefined})}`,
                              `${route.plate}`,
                              "width=900,height=750"
                            );
                            doc.title = route.plate;
                          }}
                        >
                          {route.plate}
                        </Button>
                      ) : (
                        <span className="text-muted">
                          {"-"}
                        </span>
                      )}
                    </td>
                    <td>
                      <span>
                        {route.passengerList?.length}
                        <FontAwesomeIcon
                          onClick={() => handleShowPassengerListModal(route.passengerList, route)}
                          icon={faEdit}
                          color="black"
                          style={{ marginLeft: 5, cursor: "pointer" }}
                        />
                      </span>
                    </td>
                    <td>
                      {route.capacity ? (
                        <span>{route.capacity}</span>
                      ) : (
                        <span className="text-muted">
                          {"-"}
                        </span>
                      )}
                    </td>
                    <td>
                      <a
                        target="_blank"
                        href={`http://${window.location.host}/vehicleDetail/${
                          route.vehicleId
                        }?details=${JSON.stringify({...route, passengerList: undefined})}`}
                      >
                        <div
                          title="GPS"
                          style={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          <span className="disappear-on-small">GPS</span>
                          {route?.lastLocation ? (
                            <FontAwesomeIcon
                              icon={faCircleCheck}
                              color="green"
                              style={{ marginLeft: 5 }}
                            />
                          ) : (
                            <FontAwesomeIcon
                              icon={faCircleXmark}
                              color="red"
                              style={{ marginLeft: 5 }}
                            />
                          )}
                        </div>
                      </a>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </Table>
      </Modal.Body>
      <Modal.Footer>
        <Button
          style={{ float: "left" }}
          onClick={(e) => {
            showDocModal(true);
          }}
        >
          Toplu evrak listesi
        </Button>
        <Button variant="secondary" onClick={handleClose}>
          Kapat
        </Button>
      </Modal.Footer>
    </Modal>
    <PassengerListModal
        onClose={() => setShowPassengerListModal(false)}
        show={showPassengerListModal}
        nameList={currentNameList}
        route={currentRoute}
        handlePassengerAdd={handlePassengerListChange}
        projectId={projectId}
    />
    </>
  );
};
